@import "../../Assets/styles/variables";

.profilecard {
  display: flex;
  flex-direction: column;
  padding: 10px 5px 0;
  border: 1px solid #DFE9F8;
  border-radius: 10px;
  position: relative;

  .badge {
    position: absolute;
    top: 8px;
    width: 10%;
    height: 10%;
    left: 14px;
  }

  .like {
    position: absolute;
    top: 8px;
    right: 14px;
    width: 8%;
    height: 8%;
  }

  .top {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 50px 60px;
    position: relative;
    overflow: visible;

    .name {
      color: #072753;
      margin-top: 10px;
    }

    .mail {
      color: #738BAC;
      margin-top: 5px;
      font-size: 12px;
    }

    hr {
      border: none;
      border-top: 1px solid #DFE9F8;
      color: #DFE9F8;
      text-align: center;
      width: 105%;
      bottom: 5px;
      position: absolute;
    }
  }

  .bottom {
    padding: 0 8px 6px;

    .title {
      font-size: 14px;
    }

    .left {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 10px;

      .location {
        font-size: 14px;
        img {
          height: 13px;
          width: 10px;
          margin-right: 5px;
        }
      }

      .rate {
        font-size: 14px;
        img {
          height: 18px;
          margin-right: 5px;
        }
      }
    }
  }
}
@import "../../../../Assets/styles/variables";

.allproject {
  padding: 20px;

  .actions {
    display: flex;
    flex-direction: row;
    margin: -50px 0 30px 0;
    justify-content: flex-end;
  }

  .container {
    display: flex;
    flex-direction: column;
    background: white;
    padding: 25px;
    border-radius: 10px;

    .top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .search {
        position: relative;

        input {
          width: 380px;
          height: 45px;
          color: #7f96b5;
          border-radius: 5px;
          border: 1px solid $wklighterblue;
          background-color: #fff;
          padding: 0.375rem 1.2rem;
          outline: none;
          font-size: 14px;
        }

        .inGlass {
          position: absolute;
          right: 15px;
          top: 18px;
          height: 30%;
        }
      }

      .value {
        font-weight: bold;
      }
    }

    .cards {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      margin-top: 40px;
    }

    .paginate {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 5px;
      padding: 20px 0;

      .numbers {
        display: flex;
        flex-direction: row;

        p {
          margin-right: 10px;
          background: #cae8f5;
          padding: 5px 8px;
          font-size: 12px;
          color: #0251bc;
          font-weight: bold;

          &:first-of-type {
            background: #0251bc;
            color: white;
          }
        }
      }

      .navarrow {
        display: flex;
        flex-direction: row;

        .arrow {
          margin-right: 10px;
          background: #0251bc;
          padding: 5px 10px;
          width: 25px;
          height: 26px;
          position: relative;

          img {
            position: absolute;
            height: 9px;
            top: 7.5px;
          }
        }
      }
    }
  }
}

.empty {
  background: #fff;
  height: 75vh;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  p {
    font-size: 22px;
    line-height: 192%;
    margin: 50px 0;
    width: 40%;
    text-align: center;
  }
  footer {
    display: flex;
    align-items: center;
  }
}

@import "../../../../../Assets/styles/variables";
.projectDetail {
  display: flex;
  flex-direction: column;

  .actions {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 50px;
  }

  section {
    display: flex;
    overflow: auto;

    .item {
      flex: 1;
      // min-width: 70%;
      margin-right: 20px;
      color: $wkblack3;

      &.left {
        margin-left: 40px;
        margin-right: 0;
      }
      .topImg {
        height: 23vh;
        border-radius: 8px;
        background: $wkgray3;
        margin-bottom: 20px;
        overflow: hidden;
        align-items: center;
        justify-content: center;
        display: flex;
        img {
          width: 100%;
          height: 100%;
        }
      }

      h1 {
        font-weight: 500;
        font-size: 18px;
        line-height: 147%;
        margin: 25px 0;
      }
      p {
        font-size: 13px;
        line-height: 200%;
        text-align: justify;

        &.title {
          color: $wkblack;
        }
      }
      h2 {
        font-size: 18px;
        line-height: 156%;
      }

      .card {
        padding: 35px;
        background: $wklightblue2;
        border-radius: 8px;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;

        &.compact {
          height: auto;
          padding: 10px 30px;
          margin-top: 50px;
        }
        .checks {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 20px;
          margin-top: 20px;
          button {
            margin-right: 40px;
            margin-bottom: 30px;
          }
        }
        .foot {
          flex: 1;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
        }
        .pills {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 20px;
          .pill {
            min-width: 105px;
            text-align: center;
            margin-right: 12px;
            margin-bottom: 18px;
            font-size: 15px;
            line-height: 139%;
            color: $wkblue;
            padding: 5px 22px;
            background: $wkashblue2;
            border-radius: 2px;
          }
        }
      }
    }

    main {
      display: flex;
      flex-direction: column;
      padding: 20px 25px;
      background: $wklightblue3;
      border-radius: 8px;
      color: $wkblueblack5;
      width: 274px;
      height: 232px;
      justify-content: space-between;
      margin-top: 20px;
      align-items: flex-start;

      &.green {
        margin-left: 40px;
        color: $wkgreen;
        background: $wklightgreen;
      }
      span,
      h1 {
        margin: 2px 0 !important;
        padding: 0 !important;
      }
      h1 {
        font-size: 20px !important;
        line-height: 156% !important;
      }
      span {
        font-size: 14px !important;
        line-height: 156% !important;
      }
    }
  }

  .divider {
    height: 1px;
    background: $wklastgray;
    width: 100%;
    margin: 40px 0;
  }
}

.mainSection {
  max-width: 1100px;
  margin: 0 auto;
  padding: 70px 0;
}

.mainHeading {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 50px;
  line-height: 1.23;
  color: #0251bc;
  text-align: center;
  margin: 25px 0;
}

.cardContainer {
  display: grid;
  grid-template-columns: 23.5% 23.5% 23.5% 23.5%;
  column-gap: 2%;
  row-gap: 35px;
  padding-top: 80px;
}

@import "../../../../Assets/styles/variables";

.allProject {
  .top {
    display: flex;
    flex-direction: column;
    padding: 40px 5rem 7rem;

    .heading {
      padding: 50px 50px 70px;
      margin: 0 auto;

      h1 {
        color: #0251bc;
        font-size: 40px;
        font-weight: 450;
      }

      p {
        color: #0251BC;
        font-size: 16px;
        text-align: center;
        margin-top: 10px;
      }
    }

    .topProjects {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 40px;
    }

    .dots {
      height: 12px;
      width: 5%;
      margin: 0 auto;
    }
  }

  .middle {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    background: #F8FDFF;
    padding: 7rem ;
  }
}
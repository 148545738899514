@import "../../../../../Assets/styles/variables";

.card {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 10px;
  background: white;
  border: 1px solid #DFE9F8;
  min-width: 25%;
  max-width: 25%;
  margin: 0 10px;

  img {
    height: 50%;
  }

  .top {
    position: absolute;
    top: 20px;
    width: 8%;
    height: 4%;
    left: 12px;
  }

  .date {
    position: absolute;
    top: 17px;
    right: 25px;
    color: white;
  }

  .straight {
    height: 55% fixed;
  }

  .text {
    padding: 10px 20px;
    font-size: 14px;
    font-weight: bold;
    color: #03306d;
  }

  .bottom {
    display: flex;
    padding: 5px 20px;
    margin: 0 0 -80px 0;

    .side {
      display: flex;
      // align-items: center;

      .pic {
        width: 20%;
        height: 70%;
      }

      div {
        margin-left: 10px;
        width: 100%;

        .name {
          margin-bottom: 5px;
          font-size: 13px;
          color: #44628c;
        }

        .location {
          font-size: 13px;
          color: #0355c5;

          .locate {
            margin-right: 3px;
            height: 5%;
            width: 6%;
          }
        }
      }
    }
  }
}
@import "../../Assets/styles/variables";

.checkbox {
  display: flex;
  align-items: center;
  color: $wkblack2;
  padding: 0;
  border: none;
  background: none;
  font-size: 14px;
  line-height: 133%;
  outline: none;
  cursor: pointer;
  .check {
    width: 18px;
    height: 18px;
    background: lighten($wkgray2, 20);
    border-radius: 3px;
    margin-right: 5px;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    &:after {
      content: url("../../Assets/images/checkbox.svg");
      width: 100%;
      height: 100%;
      display: none;
      align-items: center;
      justify-content: center;
      background: $wkblue;
      position: absolute;
      top: 0;
      left: 0;
      padding-bottom: 5px;
    }

    &.checked {
      &:after {
        display: flex;
      }
    }
  }
}

.container {
  display: flex;
  align-items: center;
}

// https://css-tricks.com/custom-styling-form-inputs-with-modern-css-features/
@supports(-webkit-appearance: none) or (-moz-appearance: none) {
  input[type='checkbox'] {
    --active: #0251bc;
    --active-inner: #fff;
    --focus: 2px rgba(39, 94, 254, .3);
    --border: #BBC1E1;
    --border-hover: #275EFE;
    --background: #fff;

    -webkit-appearance: none;
    -moz-appearance: none;
    height: 21px;
    width: 21px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    border-radius: 3px;
    background: lighten($wkgray2, 20);
    transition: background .3s, border-color .3s, box-shadow .2s;

    &:after {
      content: '';
      display: block;
      position: absolute;
      transition: transform .3s  ease, opacity .2s;
      opacity: var(--o, 0);

      width: 5px;
      height: 9px;
      border: 2px solid var(--active-inner);
      border-top: 0;
      border-left: 0;
      left: 7px;
      top: 4px;
      transform: rotate(var(--r, 20deg));
    }

    &:checked {
      --b: var(--active);
      --bc: var(--active);
      --o: 1;

      --r: 43deg;
      background: var(--b, var(--background));
      border: none;
    }

    &:hover {
      &:not(:checked) {
        &:not(:disabled) {
          --bc: var(--border-hover);
        }
      }
    }

    &:focus {
      box-shadow: 0 0 0 var(--focus);
    }

    & + label {
      line-height: 21px;
      display: inline-block;
      vertical-align: top;

      margin-left: 5px;
      color: #04367a;
      padding: 0;
      border: none;
      background: none;
      font-size: 14px;
      line-height: 133%;
      outline: none;
      cursor: pointer;
    }
  }
}
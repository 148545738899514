@import "../../../../Assets/styles/variables";

.allworkspace {
  padding: 20px;

  .container {
    display: flex;
    flex-direction: column;
    background: white;
    padding: 25px;
    border-radius: 10px;

    .top {
      display: flex;
    }

    .cards {
      display: grid;
      grid-template-columns: repeat(3, 31.6%);
      gap: 30px 2%;
      padding-top: 60px;
    }

    .paginate {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 5px;
      padding: 20px 0;

      .numbers {
        display: flex;
        flex-direction: row;

        p {
          margin-right: 10px;
          background: #cae8f5;
          padding: 5px 8px;
          font-size: 12px;
          color: #0251bc;
          font-weight: bold;

          &:first-of-type {
            background: #0251bc;
            color: white;
          }
        }
      }

      .navarrow {
        display: flex;
        flex-direction: row;

        .arrow {
          margin-right: 10px;
          background: #0251bc;
          padding: 5px 10px;
          width: 25px;
          height: 26px;
          position: relative;

          img {
            position: absolute;
            height: 9px;
            top: 7.5px;
          }
        }
      }
    }
  }
}

.empty {
  background: #fff;
  height: 75vh;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  p {
    font-size: 22px;
    line-height: 192%;
    margin: 50px 0;
    width: 40%;
    text-align: center;
  }
  footer {
    display: flex;
    align-items: center;
  }
}


.notification {
  display: flex;

  .left {
    padding: 20px 25px;
    width: 50%;
    background: #fbfcff;
    margin-right: 30px;

    .top {
      display: flex;
      justify-content: space-between;
    }

    .container {
      height: 80vh;
      overflow-y: auto;
      margin-top: 40px;
    }
  }

  .right {
    flex: 1;
    margin-top: -20px;
    padding: 20px;
    background: white;
  }
}
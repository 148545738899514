$wkblue: #0251bc;
$wkdarkblue: #053a82;
$wkblack: #00051e;
$wkblack2: #04367a;
$wkblack3: #072753;
$wkblueblack: #042757;
$wkblueblack2: #073676;
$wkblueblack3: #034094;
$wkblueblack4: #063b85;
$wkblueblack5: #033881;
$wkred: #f12f2f;
$wklightblue: #b6e4ff;
$wklightblue2: #f9fbff;
$wklightblue3: #f1fbff;
$wklighterblue: #c9d7eb;
$wkmorelightblue: #ccedff;
$wkskyblue: #e2f6ff;
$wkgrayblue: #73859e;
$wkgray: #3b4c64;
$wkashblue: #bfccdc;
$wkashblue2: #dae7f8;
$wkwhite2: #fbfcfe;
$wkgray2: #919db6;
$wkgray3: #f5f8fd;
$wkdeepblue: #08659b;
$wklightgray: #728094;
$wklastgray: #e4ecf0;
$wkgray4: #667d9d;
$wkgreen: #07862a;
$wklightgreen: #e0fdf4;

@import "../../../../../Assets/styles/variables";

.recruitercontract {

  .top {
    display: flex;
    flex-direction: column;
    padding: 20px 155px 100px;

    h2,
    h3 {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 40px;
    }

    h3 {
      margin-bottom: 20px;
    }

    p {
      font-size: 14px;
      text-align: justify;
      margin-bottom: 40px;
    }

    .action {
      display: flex;
      flex-direction: row;
      margin-top: 30px;
    }
  }
}

.bottom {
  margin-top: 20px;
}
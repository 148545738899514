@import "../../../../Assets/styles/variables";

.allWorkspace {
  .top {
    display: flex;
    flex-direction: column;
    padding: 40px 5rem 7rem;

    .heading {
      padding: 50px 50px 40px;
      margin: 0 auto;

      h1 {
        color: #0251bc;
        font-size: 40px;
        font-weight: 450;
      }
    }

    .hold {
      margin: 0 auto;
      width: 68%;
      margin-bottom: 40px;
    }

    .topProjects {
      display: grid;
      grid-template-columns: 23.5% 23.5% 23.5% 23.5%;
      column-gap: 2%;
      row-gap: 35px;
      padding-top: 80px;
      margin-bottom: 50px;
    }
  }
}
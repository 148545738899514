@import "../../Assets/styles/variables";

.card {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background: white;
  border: 1px solid #dfe9f8;
  width: 100%;
  height: 21rem;
  position: relative;
  box-shadow: 0px 6px 40px rgba(7, 48, 105, 0.08);

  img {
    width: 100%;
    height: auto;
    position: relative;
  }

  .straight {
    height: 55% fixed;
  }

  .text {
    position: absolute;
    color: white;
    padding: 10px 20px;
    font-size: 15px;
    font-weight: bold;
    top: 12rem;
  }

  .bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 20px;
    margin: 10px 0 0 0;

    .side {
      display: flex;
      flex-direction: row;
      align-items: center;

      div {
        width: 100%;

        .name {
          margin-bottom: 5px;
          font-size: 14px;
        }
        .location {
          font-size: 13px;
          color: #0251bc;

          .locate {
            margin-right: 7px;
            height: 9%;
            width: 9%;
          }
        }
      }
    }

    .due {
      margin-top: 15px;
      padding: 10px;
      font-size: 11px;
    }
  }
}

@import "../../Assets/styles/variables";

.footer {
  display: flex;
  flex-direction: column;
  min-height: 70vh;
  background: $wkblack;
  padding: 100px;
  color: $wkskyblue;

  .top {
    div {
      margin: 0 auto;
      text-align: center;
      font-size: 25px;
      max-width: 50%;
      line-height: 1.5;
    }

    button {
      margin: 0 auto;
      margin-top: 25px;
    }
  }

  .foot {
    display: flex;
    justify-content: space-between;
    margin-top: 200px;

    .copyright {
      display: flex;
      align-items: center;

      img {
        margin-right: 35px;
      }

      span {
        font-weight: 300;
        font-size: 14px;
        line-height: 179%;
      }
    }

    .links {
      a {
        color: $wkskyblue;
        font-weight: 300;
        font-size: 14px;
        line-height: 179%;
        margin: 0 20px;
      }
    }
  }
}
@import "../../../../Assets/styles/variables";

.newDispute {
  padding: 20px 0;
  display: flex;
  flex-direction: column;

  .top {
    display: flex;
    flex-direction: row;
    margin: -50px 0 30px 0;
    justify-content: flex-end;
  }

  .midSection {
    display: flex;
    flex-direction: column;
    background: white;
    padding: 45px 120px 120px;
    margin-bottom: 50px;

    .actions {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 30px;
    }
  }
}
@import "../../../../../Assets/styles/variables";

.contract {

  .actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 30px;
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    .left {
      width: 100%;
      margin-right: 50px;

      h2 {
        font-size: 20px;
        font-weight: bold;
      }

      p {
        text-align: justify;
        font-size: 14px;
      }
    }

    .right {
      display: flex;
      flex-direction: column;

      .inset {
        background: #f9faff;
        padding: 20px 30px;
        position: relative;

        Button {
          position: absolute;
          right: 60px;
          top: 180px;
        }
      }

      .commentarea {
        background: #f9faff;
        margin-top: 20px;
        padding: 20px 30px;

        .accept {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
        }
      }
    }
  }
}
@import "../../Assets/styles/variables";

.card {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 10px;
  background: white;
  border: 1px solid #DFE9F8;
  width: 100%;
  height: 15rem;
  margin-bottom: 20px;

  img {
    height: 60%;
  }

  .straight {
    height: 55% fixed;
  }

  .bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 20px;
    margin: 25px 0 -30px;

    .left {
      .text {
        padding: 0 0 10px;
        font-size: 15px;
        font-weight: 450;
      }
      .location {
        font-size: 15px;

        .locate {
          position: relative;
          margin-right: 7px;
          top: 1px;
          width: 5.6%;
        }
      }
    }

    .right {
      .name {
        padding: 0 0 10px;
        font-size: 14px;
        color: $wkblue;
        text-align: right;
      }

      .due {
        font-size: 14px;
        text-transform: uppercase;
      }
    }
  }
}
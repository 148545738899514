@import "../../Assets/styles/variables";

.grid {
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 15px));
  margin: 0;
  padding: 0;
  grid-column-gap: 30px;
  grid-row-gap: 0;
}

@import-normalize;
@import "./Assets/styles/variables";
@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700&display=swap");

/* autoprefixer grid: autoplace */

* {
  box-sizing: border-box;
  margin: 0;
}
html,
body {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $wkblack;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
}

a {
  color: $wkblue;
  text-decoration: none;
}

@import "../../Assets/styles/variables";

.input {
  &.margin {
    margin: 25px 0;
  }

  label {
    color: $wkblueblack;
    font-size: 14px;
    font-weight: 500;
  }
  input {
    background-color: #fff;
    border: 1px solid $wklighterblue;
    color: $wkgrayblue;
    border-radius: 5px;
    width: 100%;
    height: 45px;
    padding: 0.375rem 1.2rem;
    font-size: 14px;
    margin-top: 10px;
    outline: none;

    &:focus {
      color: $wkblue;
      border: 1px solid #0251bc;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      // border: 1px solid $wklighterblue;
      -webkit-text-fill-color: $wkblue;
      box-shadow: 0 0 0px 1000px #fff inset;
      background-color: #fff;
      background: #fff;
      // font-size: 18px;
      color: $wkgrayblue;
    }

    &:focus,
    &:hover {
      user-select: auto;
    }
  }
}

@import "../../Assets/styles/variables";

.sidebar {
  width: 250px;
  background: #00051e;
  display: flex;
  flex-direction: column;
  align-items: center;
  //   justify-content: center;
  padding-top: 40px;

  .avatar {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: $wkgray3;
    position: relative;

    .place {
      position: absolute;
      font-size: 45px;
      font-weight: 1000;
      color: #0251bc;
      top: 11px;
      left: 8px;
    }
  }
  .name {
    font-weight: bold;
    font-size: 18px;
    line-height: 133%;
    margin-top: 20px;
    color: $wkgray3;
  }
  .viewP {
    font-weight: bold;
    font-size: 12px;
    line-height: 133%;
    margin-top: 10px;
    color: $wkmorelightblue;
    border: none;
    background: none;
    outline: none;
  }
  .rating {
    margin-top: 7px;
  }
  .share {
    padding: 15px 20px;
    border: none;
    border-radius: 5px;
    background: $wkskyblue;
    color: $wkblue;
    display: flex;
    align-items: center;
    outline: none;
    font-weight: 500;
    font-size: 14px;
    margin-top: 35px;
    cursor: pointer;
    img {
      margin-right: 8px;
    }

    &:active {
      transform: scale(1.05);
    }
  }
  .linkContainer {
    border-radius: 40px 40px 0px 0px;
    background: #020930;
    width: 100%;
    margin-top: 20%;
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding-top: 30px;

    .links {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      overflow-y: auto;
      flex: 1;

      a {
        padding: 20px 35px;
        color: #a1abe2;
        font-size: 15px;
        line-height: 139%;
        display: flex;
        align-items: center;
        width: 100%;

        &:hover {
          background: rgba(237, 248, 255, 0.04);
        }

        &.active {
          background: rgba(237, 248, 255, 0.04);
          border-right: 4px solid #cae8f5;
          color: white;
        }

        span {
          display: flex;
          align-items: center;
          width: 40px;
          img {
            height: 20px;
            width: 20px;
          }
        }
      }
    }
    .links::-webkit-scrollbar {
      display: none;
    }
  }
}

@import "../../../../Assets/styles/variables";

.oneworkspace {
  .top {
    display: flex;
    flex-direction: column;
    padding: 40px 5rem 4rem;

    .title {
      padding: 30px 0 40px;
      font-family: "Roboto", sans-serif;
      font-weight: 400;
    }

    .details {
      display: flex;

      .left {
        flex: 1 1 0px;

        .wksp {
          border-radius: 10px;
          width: 95%;
          height: 75%;
        }

        .disclaimer {
          margin-top: 20px;

          .disclaim {
            margin: 0 0 15px 20px;
            position: relative;
            color: $wkblack3;
            font-weight: 500;

            img {
              position: absolute;
              left: -20px;
              top: 2px;
              height: 90%;
            }
          }

          .note {
            color: #4f627c;
            font-size: 14px;
            text-align: justify;
            width: 95%;
            line-height: 1.8;
          }
        }
      }

      .right {
        flex: 1 1 0px;
        padding: 10px;

        .location {
          font-size: 17px;

          img {
            width: 10px;
            height: 13px;
            margin-right: 8px;
          }
        }

        .body {
          padding: 20px 0 35px;
          font-family: "Roboto", sans-serif;
          font-size: 14px;
          text-align: justify;
          line-height: 1.8;
        }

        .under {
          border-bottom: 1px solid #cfe0f8;
          border-top: 1px solid #cfe0f8;

          .feat {
            margin-top: 20px;
            font-weight: 430;
            font-size: 14px;
          }

          .more {
            display: flex;
            padding: 30px 0 45px 10px;

            .item {
              margin: 0 3rem 0 2rem;
              position: relative;

              img {
                position: absolute;
                right: 60px;
                top: -10px;
                height: 35px;
              }

              &:nth-of-type(2) {
                img {
                  left: -40px;
                }
              }
            }
          }

        }

        .info {
          display: flex;
          justify-content: space-between;

          .tag {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 45%;

            .hours {
              margin: 20px 0 25px;
              position: relative;
              font-weight: 430;
              font-size: 14px;

              img {
                position: absolute;
                right: 10.5rem;
                height: 15px;
              }
            }

            .mid {
              display: flex;
              justify-content: space-between;
            }

            .days {
              margin-bottom: 25px;

              .day {
                font-weight: 400;
                font-size: 14px;
                margin-bottom: 5px;
              }

              .time {
                color: #8298b6;
                font-size: 14px;
              }
            }
          }

          .action {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .cadence {
              margin: 20px 0 0;
              font-weight: 430;
              font-size: 14px;
            }

            .item {
              margin: 0 3rem 0 2rem;
              position: relative;

              img {
                position: absolute;
                right: 79px;
                top: -10px;
                height: 35px;
              }
            }
          }
        }

      }
    }
  }

  .middle {
    display: flex;
    flex-direction: column;
    padding: 20px 5rem 4rem;
    background: #f8fdff;

    h3 {
      font-family: "Roboto", sans-serif;
      font-size: 25px;
      color: #0251bc;
      font-weight: 400;
      padding: 20px 50px 40px;
      margin: 0 auto;
    }

    .otherworkspaces {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 40px 0;
    }

    .dots {
      height: 12px;
      width: 5%;
      margin: 0 auto;
    }
  }
}
@import "../../Assets/styles/variables";

.nav {
  padding: 20px 35px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    display: flex;
    align-items: center;

    h2 {
      font-weight: 500;
      font-size: 18px;
      line-height: 133%;

      color: $wkblue;
      text-transform: capitalize;
    }

    .leftArrow {
      background-color: #0251bc;
      height: 30px;
      width: 30px;
      border: 0;
      display: flex;
      align-items: center;
      border-radius: 50%;
      justify-content: center;
      margin-right: 15px;
      outline: none;
      cursor: pointer;
    }
  }

  .right {
    display: flex;
    align-items: center;

    .seperator {
      height: 44px;
      width: 0;
      border: 1px solid $wkashblue;
      margin: 0 30px;
    }

    button {
      background: none;
      border: none;
      padding: 0;
      margin: 0;
      cursor: pointer;
      outline: none;
    }

    .notify {
      position: relative;
      span {
        position: absolute;
        right: -9px;
        top: -6px;
        background: $wkred;
        padding: 3px;
        font-weight: 400;
        font-size: 10px;
        color: #fff;
        border-radius: 50%;
      }
    }
  }
}

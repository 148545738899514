@import "../../Assets/styles/variables";

.comment {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  border-bottom: 1px solid #daebf3;

  &:last-of-type {
    border-bottom: none;
  }

  .top {
    display: flex;
    align-items: center;

    .topImg {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      overflow: hidden;

      img {
        height: 100%;
        width: 100%;
      }
    }

    .side {
      margin-left: 10px;

      &.altVersion {
        display: flex;
        justify-content: space-between;
        flex: 1;
        align-items: center;
      }

      .name {
        line-height: 1;
        color: #072753;
        font-size: 13px;
        font-weight: bold;
      }
      .time {
        line-height: 1;
        color: #7696a6;
        font-size: 13px;
        margin-top: 7px;
      }
    }
  }

  .body {
    text-align: justify;
    font-size: 13px;
    font-family: "Roboto";
    margin-top: 15px;
    color: #072753;
    line-height: 2;
    padding-bottom: 15px;
  }
}

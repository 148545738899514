@import "../../../../../Assets/styles/variables";

.detail {
  display: flex;
  flex-direction: column;

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      color: #2e4769;
      font-size: 14px;
    }

    .side {
      img {
        width: 35%;
        height: 35%;

        &:last-of-type {
          margin-left: 15px;
        }
      }
    }
  }

  .mid {
    display: flex;
    align-items: center;
    margin: 25px 0;

    .imgWrapper {
      width: 15%;
      width: 15%;
      margin-right: 15px;

      img {
        width: 100%;
        width: 100%;
      }
    }

    .__side {
      .top {
        margin-bottom: 5px;

        .sender {
          color: #072753;
          font-size: 17px;
          font-weight: 450;
          margin-right: 15px;
        }

        .loc {
          background: #eaf3fe;
          color: #6a8db9;
          padding: 5px 8px;
          border-radius: 5px;
        }
      }

      .mail {
        color: #2e4769;
        font-weight: 400;
      }
    }
  }

  .bottom {
    display: flex;
    flex-direction: column;

    .content {
      line-height: 1.5;
      font-size: 14px;
      text-align: left;
    }

    .botCard {
      display: flex;
      flex-direction: column;
      padding: 20px;
      margin-top: 30px;
      background: #eff9ff;

      .title {
        color: #072753;
        font-size: 17px;
        font-weight: 450;
        margin-bottom: 20px;
      }

      .body {
        line-height: 1.5;
        font-size: 14px;
        text-align: left;
        margin-bottom: 20px;
      }

      .make {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

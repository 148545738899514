
.recommend {
  display: flex;
  flex-direction: column;
  background: white;
  padding: 20px;

  .info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;

    p {
      font-weight: bold;
      font-size: 14px;
    }

    .navarrow {
      display: flex;
      flex-direction: row;

      .arrow {
        margin-right: 10px;
        background: #0251BC;;
        padding: 5px 10px;
        width: 25px;
        height: 26px;
        position: relative;

        img {
          position: absolute;
          height: 9px;
          top: 7.5px;
        }
      }
    }
  }

  .card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
@import "../../../../Assets/styles/variables";

.dashboard {
  flex: 1;
  display: flex;
  width: 75vw;
  flex-direction: column;
  .top {
    display: flex;
    flex-direction: column;
    background: white;
    padding: 25px;
    width: 100%;

    .upper {
      display: flex;
      justify-content: space-between;
      padding-bottom: 40px;

      .left {
        display: flex;

        .balance {
          background: $wkblue;
          border-radius: 5px;
          position: relative;
          color: white;
          vertical-align: middle;
          font-size: 16px;
          font-weight: bold;
          padding: 10px 120px 5px 60px;
          overflow: hidden;

          .wallet {
            position: absolute;
            left: 18px;
            top: 10px;
            height: 53%;
          }

          .pig {
            position: absolute;
            right: -1px;
            top: 0;
            bottom: 0;
            height: 99%;
          }
        }
      }

      .right {
        display: flex;
      }
    }

    .search {
      p {
        font-size: 14px;
        line-height: 139%;
        margin-bottom: 10px;
      }
    }

    .bottom {
      display: flex;
      justify-content: space-between;
      margin-top: 35px;

      .left {
        p {
          font-size: 14px;
          line-height: 139%;
          margin-bottom: 20px;
          font-weight: 500;
        }

        img {
          height: 42%;
          width: 42%;
        }
      }

      .right {
        p {
          font-size: 14px;
          line-height: 139%;
          margin-bottom: 20px;
          font-weight: 500;
        }

        .skills {
          display: flex;
          margin-right: 70px;
        }
      }
    }
  }

  .middle {
    padding: 25px 0;
    width: 100%;
    overflow: auto;

    p {
      font-size: 15px;
      line-height: 139%;
      margin-bottom: 20px;
      font-weight: 500;
    }

    .projectBar {
      display: flex;
      flex-direction: column;

      .cards {
        display: flex;
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
      }

      .cards::-webkit-scrollbar {
        display: none;
      }

      .onit {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;

        .navarrow {
          display: flex;
          margin: 10px 0 0 15px;

          .arrow {
            margin-right: 10px;
            background: #0251bc;
            padding: 5px 10px;
            width: 26px;
            height: 28px;
            position: relative;
            border-radius: 15px;

            img {
              position: absolute;
              height: 11px;
              top: 7.5px;
            }
          }
        }
      }
    }
  }

  .bottom {
    padding: 25px;
    width: 100%;
    background: white;

    .head {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      .intvw {
        font-weight: 500;
      }

      .vwall {
        color: $wkblue;
        font-weight: 500;
        // font-size: 14.5px;

        img {
          height: 10px;
          width: 10px;

          &:first-of-type {
            margin-left: 8px;
          }

          &:last-of-type {
            margin-left: -4px;
          }
        }
      }
    }

    .contain {
      display: flex;
      flex-direction: column;
    }
  }
}

@import "../../Assets/styles/variables";

.dashboard {
  display: flex;
  height: 100vh;

  .container {
    flex: 1;
    display: flex;
    flex-direction: column;

    .inner {
      flex: 1;
      background: $wkgray3;
      padding: 55px 37px;
      height: 100vh;
      overflow: auto;
    }
  }
}

.offline {
  background-color: $wkred;
  color: $wkwhite2;
  text-transform: capitalize;
  font-weight: bold;
  transition: all 0.5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0;
  // position: fixed;
  // width: 100vw;
  // z-index: 9999;
  &.show {
    height: 40px;
  }
}

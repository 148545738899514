@import "../../Assets/styles/variables";

.routetab {
  width: 100%;
  background: #ffffff;
  min-height: 100vh;
  border-radius: 12px;

  .nav {
    display: flex;
    border-bottom: 1px solid #daebf3;
    a {
      font-weight: 500;
      font-size: 14px;
      line-height: 113%;
      color: $wkgray4;
      padding: 20px 20px;
      display: flex;
      &.active {
        border-bottom: 3px solid $wkblue;
        color: $wkblue;
      }
    }
  }

  .content {
    padding: 30px;
  }
}

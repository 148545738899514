@import "../../../../Assets/styles/variables";

.oneworkspace {
  background: white;
  padding: 30px 30px 8rem;

  .details {
    display: flex;
    // padding: 40px 5rem 4rem;

    .left {
      flex: 1.5 1 30px;

      .wksp {
        border-radius: 10px;
        width: 95%;
        height: 55%;
      }

      .disclaimer {
        margin-top: 30px;

        .disclaim {
          margin: 0 0 15px 20px;
          position: relative;
          color: $wkblack3;
          font-weight: 500;

          img {
            position: absolute;
            left: -20px;
            top: 2px;
            height: 90%;
          }
        }

        .note {
          color: #4f627c;
          font-size: 14px;
          text-align: justify;
          width: 95%;
          line-height: 1.8;
        }
      }
    }

    .right {
      flex: 1 1 0px;
      padding: 10px;

      .title {
        padding: 0 0 10px;
        font-family: "Roboto", sans-serif;
        font-weight: 500;
        font-size: 18px;
      }

      .location {
        font-size: 15px;

        img {
          width: 10px;
          height: 12px;
          margin-right: 8px;
        }
      }

      .body {
        padding: 20px 0 35px;
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        text-align: justify;
        line-height: 1.8;
      }

      .under {
        border-bottom: 1px solid #cfe0f8;
        border-top: 1px solid #cfe0f8;

        .feat {
          margin-top: 20px;
          font-weight: 430;
          font-size: 14px;
        }

        .more {
          display: flex;
          padding: 30px 0 45px 10px;

          .item {
            margin: 0 3rem 0 2rem;
            position: relative;

            img {
              position: absolute;
              right: 60px;
              top: -10px;
              height: 35px;
            }

            &:nth-of-type(2) {
              img {
                left: -40px;
              }
            }
          }
        }

      }

      .info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .tag {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          // width: 45%;

          .hours {
            margin: 20px 0 25px;
            position: relative;
            font-weight: 430;
            font-size: 14px;

            img {
              position: absolute;
              right: 20rem;
              height: 15px;
            }
          }

          .mid {
            display: flex;
            justify-content: space-between;
          }

          .days {
            margin-bottom: 25px;

            .day {
              font-weight: 400;
              font-size: 14px;
              margin-bottom: 5px;
            }

            .time {
              color: #8298b6;
              font-size: 14px;
            }
          }
        }

        .action {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .cadence {
            margin: 20px 0 0;
            font-weight: 430;
            font-size: 14px;
          }

          .nnow {
            display: flex;
            justify-content: space-between;
            // align-items: flex-end;

            .item {
              margin: 30px 3rem 0 2rem;
              position: relative;

              img {
                position: absolute;
                right: 60px;
                top: -10px;
                height: 35px;
              }
            }
          }
        }
      }

    }
  }
}
